 .members-main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  width: 100%;
  min-height: calc(100vh - 75px);
  
}

.members-core{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
  
  justify-content:space-evenly;
  gap: 30px;
  
  
}
.members-card-bg-img{
  height: 300px;
  /* width: 90%; */
  max-width: 90%;
  position: absolute;
  left: 5%;
  opacity: 50%;

}
.members-title-name-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.members-founder-card-info-icons-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.members-founder-card-bottom{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.members-cards-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}
.members-heading-text{
  font-family: var(--font-family-akira);
  font-weight: 600;
  margin: 0px;
  text-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
}
.members-horizontal-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 800px;
  height: 250px;
  background-color: var(--member-plaque);
  border-radius: 15px;
}

.members-horizontal-card-left{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  
}
.members-horizontal-card-right{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}
.members-horizontal-card-userimage{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.members-card-front,
.members-card-back{
  backface-visibility: hidden;
}
.members-card-back{
  transform: rotateY(180deg);
}
#members-row-founder{
  justify-content: space-between;
  flex-wrap: nowrap;
}
.members-cards-card{
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out 0s;
}

.members-cards-card:hover {
  /* transform: rotateY(180deg); */
}

.members-social-icons{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 25px;
}

.members-vertical-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  height: 450px;
  background-color: var(--member-plaque);
  border-radius: 15px;
  padding: 1rem;
}
.members-vertical-card-userimage{
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.members-verical-card-top{
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.members-vertical-card-bottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.members-vertical-name-text{
  font-size: xx-large;
  font-family: var(--font-family-montserrat);
  font-weight: 600;
  text-align: center;
}
.members-vertical-info-text{
  font-size: medium;
}
@media (max-width: 768px) {
  .members-horizontal-card {
    flex-direction: column;
    width: auto;
    height: auto;
    text-align: center;
  }

  .members-horizontal-card-left,
  .members-horizontal-card-right {
    padding: 1rem;
  }

  .members-horizontal-card-userimage {
    width: 150px;
    height: 150px;
  }

  .members-vertical-card {
    width: auto;
    height: auto;
  }

  .members-vertical-card-userimage {
    width: 150px;
    height: 150px;
  }

  .members-verical-card-top {
    margin-bottom: 1rem;
  }
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<KARAN CODE>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.main-cards {
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
  row-gap: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 80px;
}

.main-head {
  text-align: center;
  letter-spacing: 6px;

}
.card-container {
  perspective: 1000px;
  
}
.members-back-card-title{
  margin: 0;

}
.members-card-bottom-h2{
  margin: 0px;
  font-size: 18px;
}
.card {
  width: 250px;
  height: 22em;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
  /* margin: auto;s */
  border-radius: 2px;
  /* margin-bottom: 80px; */
}

.card:hover {
  /* transform: rotateY(180deg); */
}

.members-front,
.back {
  position: absolute;
  width: 100%;
  height: 95%;
  backface-visibility: hidden;
  /* border: 4px solid red; */
  box-shadow: 0px 0px 5px 1.5px var(--fg-color);
}

.members-front {
  /* background-image: var(--bg-image); */
  box-shadow: 0px 0px 15px 3px var(--fg-color);
  color: white;
  display: flex;
  flex-direction: column;
  height: 95%;
  border-radius: 30px;
}

.back-description-founder {
  font-size: 16px;
  text-align: left;
  align-self: flex-start;
  font-family: var(--font-family-space);
  /* padding: 0; */
  margin: 0px;
}
.members-front-founder {
  /* background-image: var(--bg-image); */
  box-shadow: 0px 0px 15px 3px var(--fg-color);
  color: white;
  display: flex;
  width: 100%;
  height: 250px;
  border-radius: 30px;
}
.bgimg-founder {
  width: 350px;
  height: 100%;
  padding-top: 0px;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  
}
.members-back-title-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.members-back-bottom-container{
  display: flex;
  flex-direction: column;
  gap: 10px;

}
.members-back-socials-container,
.members-back-skills-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 5px;
}
.back {
  box-shadow: 0px 0px 15px 3px var(--fg-color);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  transform: rotateY(180deg);
  color: white;
  padding: 10px;
  border-radius: 30px;

}

.bgimg {
  width: 100%;
  height: 75%;
  padding-top: 0px;
  object-fit: cover;
  /* padding: 6px; */
  /* border:  solid white; */
  /* for image radius */
  /* border-radius: 4px solid red; */
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  
}
.members-card-text{
  padding: 10px 0px;
}
.name{
  font-size: 18px;
}
.heading,
.name {
  text-align: left;
  font-family: var(--font-family-space);
  padding-left: 20px;
  margin: 0px;


}
.members-title{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.members-title-founder{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}
.members-card-founder-right{
  display: flex;
  width: 100%;
  padding: 10px 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.name-founder {
  text-align: left;
  margin: 0px;
  font-family: var(--font-family-space);
  font-weight: 600;

}

.heading {
  font-size: 30px;
  padding-top: 10px;
}

.role {
  font-size: 16px;
  text-align: left;
  padding-left: 20px;
  
  margin: 0px;
  font-family: var(--font-family-space);
}
.role-founder {
  font-size: 24px;
  text-align: left;
  margin: 0px;
  font-weight: 400;
  font-family: var(--font-family-space);
}
.signature-container{
  display: flex;
  justify-content: flex-end;
  border-radius: 30px;
  padding-right: 1rem;  
}
.signature-container-founder{
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  border-radius: 30px;
  width: 100%;
}
.signature-founder {
  width: 130px;
  height: 80px;
  border-radius: 15px;

}
.signature {
  width: 50px;
  border-radius: 15px;

}



.members-social-media-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.members-skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.logos {
  height: 1.5em;
  width: 2em;


}
.logos:hover {
  filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7)) brightness(150%); /* Adjust the glow effect */

}

.back-description {
  font-size: 12px;
  text-align: left;
  font-family: var(--font-family-montserrat);
  margin: 0px;
  /* padding: 0; */
}



@media (max-width:1220px){
  .members-title-founder{
    flex-wrap: wrap;
    gap: 10px;
    
  }
  .members-front-founder{
    height: 350px;
  }
}


@media (max-width:1060px){
  .name-founder{
    font-size: 28px;
  }
  .members-front-founder{
    height: 450px;
  }
  .members-founder-card-bottom{
    flex-wrap:wrap;
    justify-content: flex-end;
    gap:10px
  }
}
@media (max-width:960px){
  .members-front-founder{
    height: 450px;
  }
  .bgimg-founder{
    width: 300px;
  }

}
@media (max-width:600px){
  #members-row-founder{
    flex-wrap: wrap;
  }
}
@media (max-width:1300px){
  #members-row-founder{
    justify-content: space-evenly;
  }
  .name-founder{
    font-size: 18px;
  }
  .members-title-founder{
    flex-direction: column;
    gap: 20px;
  }
  .signature-founder{
    height: 60px;
    width: 60px;
  }
  .role-founder{
    font-size: 18px;
  }
  .members-card-founder-right{
    gap: 20px;
  }
  .members-front-founder{
    height: 500px;
    width: 250px;
    flex-direction: column;
  }
  .members-founder-card-bottom{
    justify-content: flex-end;
    gap: 0px;

  }
  .back-description-founder{
    display: none;
  }
  .bgimg-founder{
    width: 250px;
    height: 250px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 0px;
  }
  
}