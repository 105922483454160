.college-heading-text{
  font-family: var(--font-family-montserrat);
  text-align: center;
}
.college-cgc-logo{
  height: 80px;
  backdrop-filter: blur(5px);
}
.college-cec-logo{
  height: 180px;
  backdrop-filter: blur(5px);
  
}
.college-header-center-container{
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}
.college-naac-logo{
  height: 60px;
  backdrop-filter: blur(5px);
  z-index: -1;
}
.college-header-container{
  display: flex;
  padding: 0rem 0rem 0rem 0rem;
  align-items: center;
  justify-content: space-between;
  z-index: -1;
  width: 100%;
}
.college-main{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 1rem;
  padding: 2rem;
  padding-top: 0rem;
  
}

.college-text-box{
  width: 100%;
  padding: 0rem 2rem;
  padding-bottom: 1rem;
  
}
.college-college-text{
  text-align: center;
  font-family: var(--font-family-montserrat);
}
.college-college-heading{
  text-align: center;
  font-family: var(--font-family-montserrat);
}

.college-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  

}
.college-core{
  box-shadow: 2px 2px 14px 4px white;
  backdrop-filter: blur(12px);
  height: 100%;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: -1;

}




.college-college-slideshow {
  height: 100%;
  width: 100%;
}


.college-Carousel{
padding: 1rem;

}
.college-card {
box-shadow: 0 4px 8px 0 var(--black-drop-shadow);
width: 100%;
height:450px;
align-self: center;
align-items: center;
justify-self: center;
justify-content: center;
text-align: center;
font-family: arial;
}

.college-product-image {
  align-self: center;
  align-items: center;
  justify-self: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  justify-content: center;
  align-self: center;
  object-fit: cover;
}




@media(max-width:750px){
  .college-college-text{
    font-size: 15px;
  }
  .college-cgc-logo{
    height: 4vh;
  }
  .college-cec-logo{
    height: 10vh;
  }
  .college-naac-logo{
    height: 4vh;
  }
}
@media(max-width:400px){
  .college-college-text{
    font-size: 15px;
  }
  .college-header-container{
    /* flex-direction: column; */
justify-content: center;
gap: 20px;
  }
  .college-cgc-logo{
    height: 5vh;
  }
  .college-cec-logo{
    height: 10vh;
  }
  .college-naac-logo{
    height: 4vh;
  }
}
@media(max-width:300px){
  .college-college-text{
    font-size: 15px;
  }
  .college-header-container{
    /* flex-direction: column; */
justify-content: center;
gap: 5px;
  }
  .college-cgc-logo{
    height: 5vh;
  }
  .college-cec-logo{
    height: 10vh;
  }
  .college-naac-logo{
    height: 3.5vh;
  }
}


