.about-main{
  display: flex;
  
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 75px);
  /* padding: 1rem; */
}

.about-core{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
.about-box-img{
  /* height: 300px; */
  width: 20%;
}
.about-box{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 2rem;
  align-items: center;
  /* background-color: red; */
  width: 100%;
  text-align: right;
  
}
.about-text-box{
  width: 70%;
  
}
.about-text-box h1{
  color: var(--bg-color);
  
  font-family: var(--font-family-akira);
}
.about-text-box p{
  font-size: 20px;
  font-weight: 800;
  font-family: var(--font-family-space);
}
.about-core .about-box:nth-child(odd){
  background-color: var(--fg-color);
  
  flex-direction: row-reverse;
  text-align: left;
  
}
.about-core div:nth-child(even) h1{
  color: var(--fg-color);
  text-align: right;
  
}
.about-core div:nth-child(even) p{
  text-align: right;
  
}



@media (max-width: 1100px){
  .about-core .about-box:nth-child(odd){
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .about-core .about-box:nth-child(odd) h1,.about-core .about-box:nth-child(odd) p{
    
    text-align: center;
  }
  .about-box{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-box h1,.about-box p{
    text-align: center;
  }
}