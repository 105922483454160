.navbar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: transparent;
    backdrop-filter: blur(10px);
    padding: 0px 15px;
    height: 75px;
    box-shadow: 0px 0px 100px 15px #f06f0e;

}
.navbar-core{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.navbar-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;    
}
.navbar-actions{
    display: flex;
}

.navbar-pfp-container{
    width: 50px;
    height: 50px;
}
.navbar-user-profile-logo{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    object-fit: cover;
    
    
}
.navbar-halo-logo-container{
    width: 50px;
    height: 50px;
}
.navbar-logo-image {
    width: 50px;
    padding: 5px;
    object-fit: cover;
    /* backdrop-filter: blur(10px); */
    /* border: 2px solid white; */
    /* border-radius: 100%; */
    height: fit-content;

}
.navbar-logo-image:hover{
    filter: drop-shadow(0px 0px 3px white) ;
}
.navbar-links_logo {
    justify-content: space-between;
    display: flex;
    align-items: center;
    position: relative;
    gap: 1rem;
}

.navbar-links_logo p {
    color: var(--font-color);
    font-weight: 500;
    font-family: var(--font-family-akira);
    font-size: 18px;
    text-transform: uppercase;

    cursor: pointer;

}





.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;
    gap: 1rem;
}

.navbar-menu-gap{
    /* color: var(--font-color); */
    font-family: var(--font-family-akira);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    gap: 30px;
    cursor: pointer;
    
}

.navbar-sign p,
.navbar-menu_container p {
    color: var(--font-color);
    font-family: var(--font-family-akira);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    width: 60px;

    margin: 0rem 1rem;
    cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button,
.navbar-menu_container-link-sign button {

    padding: 0.5rem 0.5rem;
    color: var(--font-color);
    background-color: var(--fg-color);
    /* background-image: url(../../assets//BGs/BG\ Plain.png); */
    box-shadow: 0px 0px 10px 0px rgb(255, 255, 255);
    font-family: var(--font-family-akira);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-start;
    
    align-items: flex-start;
    padding: 0.5rem;
    flex-direction: column;
    z-index: 99999999999;
    text-align: end;
    background-color: var(--bg-color);
    /* background-image: var(--bg-image); */
    border: 1rem;
    border-color: white;
    backdrop-filter: blur(16px);
    position: absolute;
    right: 0;
    top: 40px;
    min-width: 120px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(255, 255, 255);
}
.hidden{
    display:none;
}
.navbar-menu_container p {
    margin: 0;
    width: 100%;
}
.navbar-menubar-gap{
    flex-direction: column;
    display: flex;
    gap: 15px;
    color: var(--font-color);
    font-family: var(--font-family-akira);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    gap: 15px;
    cursor: pointer;
}
.navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 350px) {
    .navbar-halo-text{
        display: none;
    }
}
@media screen and (max-width: 1050px) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }

    .navbar-menu_container-link-sign {
        display: none;
    }
}

@media screen and (max-width: 870px) {
    .navbar {
        padding: 1rem 1rem;
    }

    .navbar-menu_container-link-sign {
        display: none;
    }
}



@media screen and (max-width: 550px) {


    .navbar-sign {
        display: none;
    }

    .navbar-menu_container {
        top: 30px;
    }


    .navbar-menu_container-link-sign {
        display: block;
    }

}















.navbar-home-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-home-text:active,
.navbar-home-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-about-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-about-text:active,
.navbar-about-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-members-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-members-text:active,
.navbar-members-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-schedule-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-schedule-text:active,
.navbar-schedule-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-events-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-events-text:active,
.navbar-events-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-contact-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-contact-text:active,
.navbar-contact-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-halo-text:hover {
    /* text-shadow: 0px 0px 1px white; */
    color: var(--fg-color);
}
.navbar-halo-text{
    letter-spacing: 2px;
    
    /* color: var(--fg-color) !important; */
}
.navbar-halo-text:active,
.navbar-halo-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}



.navbar-signin-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-signin-text:active,
.navbar-signint-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}



.navbar-signup-text:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-signup-text:active,
.navbar-signup-text:focus {
    box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}

.navbar-halo-logo:hover {
    text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-halo-logo:active,
.navbar-halo-logo:focus {
    box-shadow: 2px 2px 10px var(--glow), -2px -2px 6px var(--glow);
    transform: scale(0.97);
    transition: 0.25s;
}


/* ... previous styles ... */

/* Adjust mobile menu button */


/* Spacing and padding adjustments for smaller screens */
@media screen and (max-width: 500px) {
    
    .navbar-user-profile-logo {
        width: 40px; /* Adjust profile logo size */
    }

    

    
}

/* Hide certain elements on smaller screens */
@media screen and (max-width: 380px) {
    .navbar-user-profile-logo {
        width: 40px; /* Adjust profile logo size */
    }
}

/* ... other media queries and styles ... */
@media screen and (max-width: 300px) {
    .navbar-halo-text{
        display: none;
    }
}