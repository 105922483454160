.home-hero-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -3;
}
.home-hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
}

.home-logos{
    height: 2em;
    
    width: 2em;
}
.first-char {
    color: var(--fg-color);
  }
  
  .all-zero {
    color: var(--fg-color);
  }
  
.home-logos:hover{
    color:var(--fg-color);
}

.homeicons{
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    box-shadow: 1px 1px 10px 2px var(--fg-color);
    border: 2px solid var(--fg-color);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    margin: 0.5rem 0rem;

}
.home-countdown{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    /* padding: 20px; */
    border-radius: 15px;
    /* box-shadow: 1px 1px 10px 2px var(--fg-color); */
    font-weight: 700;
}
.home-countdown-container{
    display: flex;
    gap: 25px;
    /* backdrop-filter: blur(10px); */
/* padding: 20px; */
border-radius: 15px;
    /* box-shadow: 1px 1px 10px 2px white; */
    font-size: 50px;
    font-weight: 700;
}

.home-countdown-count-heading{
    font-size: 50px;
    font-weight: 700;
    align-self: center;
}
.home-countdown-main-count-heading{
    letter-spacing: 4px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-akira);
    text-align: center;
}
.home-ellipse-img{
    width: 350px;
    height: auto;
    background-color: transparent;
    color: transparent;
    /* backdrop-filter: blur(8px); */
    /* mix-blend-mode:darken; */
    border-radius: 100%;
    box-shadow: 0px 0px 20px 5px white;
    z-index: -1;
}
.home-halolegion-logo{
    width: 250px;
    /* filter: drop-shadow(0px 0px 3px white); */
    height: auto;
    background-color: transparent;
    /* border-radius: 100%; */
    padding: 1rem;
    /* background-color: var(--bg-color); */
    /* box-shadow: 0px 0px 20px 5px white; */
    /* position: absolute; */
}
.home-hero-text{
    text-align: center;
    position: absolute;
    align-items: center;
    align-self: center;
    /* z-index: -2; */
    /* -webkit-text-stroke: 1px black; */
    overflow: hidden;
    /* display: flex; */
}
.home-hero-halocon-text{
    font-size: 80px;
    white-space:pre;
    display: flex;
    gap: 10vw;
    justify-content:space-around;
    /* padding: 0 2rem; */
    width: 100vw;
    align-items: center;
    text-align: center;
    /* letter-spacing: 150px; */
    /* padding-left: 160px; */
    text-align: center;
    mix-blend-mode: exclusion;
    
}
.home-hero-halocon-text-span{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}
.home-hero-year-text{
    font-size: 60px;
}

.home-hero-halocon-text ,.home-hero-year-text{
    font-weight: 600;
    font-family: var(--font-family-akira);
}
.registernow-text-design{
    font-family: var(--font-family-space);
    font-family: 600;
}  
 
  
.home-core{
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 75px);
    
    
}
.home-info-text{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    font-family: var(--font-family-akira);
    margin: 0;
}
.home-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;
    align-items: center;
}
.home-info-text-container{
    display: flex;
    
}
.home-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom:25px ;
    padding-top: 20px;
    min-height: calc(100vh - 75px);
    
    
}
.home-button-container{
    display: flex;
    /* padding: 15px; */
    justify-content: space-evenly;
    /* background-color: blue; */
    width: 100%;
    /* padding: 1rem; */
    
}

.home-button{
    border-radius: 5px;
}

 .home-hero-halocon-text span{
    color: white;
    text-wrap: wrap;
 }
#o-text{
    color: white !important;
}
#home-hero-text2{
    display: none;
}
#div1 span:nth-child(odd),#div2 span:nth-child(odd){
    color: var(--fg-color);
    
}

#home-hero-text2 span:nth-child(2){
    color: var(--fg-color);
}
.home-cssbuttons-io-button {
    
    background-color: transparent;
    backdrop-filter: blur(15px);
    color: var(--color-white);
    box-shadow: 0em 0em 0.5em 0.2em var(--fg-color);
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1.2em;
    font-weight: 700;
    font-size: 20px;
    font-family: var(--font-family-space);
    border-radius: 10px;
    border: 2px solid var(--fg-color);
    
    display: flex;
    align-items: center;
    
    overflow: hidden;
    position: relative;
    height: 2.8em;
    width: 12em;
    transition: 0.5s ease;
    padding-right: 3.3em;
}

.home-icon {
    background: var(--color-white);
    background-color: var(--fg-color);;
    margin-left: 1em;   
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 5px;
    /* box-shadow: 0.1em 0.1em 0.6em 0.1em var(--fg-color); */
    right: 0.3em;
    transition: all 0.3s;
  }

.home-cssbuttons-io-button:hover .home-icon {
    width: calc(100% - 0.6em);

}
.home-cssbuttons-io-button:hover {
    box-shadow: 0em 0em 0.5em 0.2em white;

}


.home-cssbuttons-io-button .home-icon svg {
    width: 1.2em;
    transition: transform 0.3s;
    color: white;
}

.home-cssbuttons-io-button:hover .home-icon svg {
transform: translateX(0.1em);
}

.home-cssbuttons-io-button:active .home-icon {
transform: scale(0.95);
}



@media (max-width: 999px){
    .home-cssbuttons-io-button{
        height:2.5em;
    }
    .home-icon {
        height: 2em;
        width: 1.8em;
    }
    .home-countdown{
        flex-direction: column;
    }
    .home-hero-text span{
        font-size: 60px;
    }
    
    .home-halolegion-logo {
      width: 250px;
    }
    .home-info-text {
        font-size: 14px; 
    }
    
    
}
@media (max-width: 650px){
    .home-hero-text span{
        font-size: 40px;
        display: none;
    }
    .home-hero-halocon-text span:nth-child(1),
    h1 span:nth-child(2),
    h1 span:nth-child(4),
    h1 span:nth-child(6) {
    color: white;
}
    .home-countdown-container, .home-countdown-container span{
        font-size: 30px ;  
        align-items: center;  
        justify-content: center;
    }
    #home-hero-text2{
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    #home-hero-text2 span{
        font-size: 40px;
    }
    .home-button-container{
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
}
@media (max-width: 460px){
    .registernow-text-design{
        font-size: 22px;
        text-align: center;
    }
    .home-countdown{
        flex-direction: column;
        gap: 2px;
        padding: 10px;
    }
    .home-main{
        padding: 5rem 1rem;
    }
    .home-countdown-count-heading{    
        font-size: 35px;
    }

    .home-hero-year-text {
      font-size: 40px; 
    }
  
    .home-info-text {
        font-size: 14px; 
    }
    
    .home-countdown-container{
        flex-direction: column;
        text-align: center;
        gap: 0;
    }
    
}
@media (max-width: 380px){
    #home-hero-text2{
        display: none;
    }
}
@media (max-width: 380px){
    .home-cssbuttons-io-button{
        height: 2.5em;
        width: 12em;
    }
    #home-hero-text2 span{
        font-size: 30px;
    }
    .home-halolegion-logo {
      width: 200px;
    }
    .home-info-text {
        font-size: 14px; 
    }
    
    .home-button-container {
        flex-direction: column; 
        gap: 10px; 
    }
    
}

/* css for home countdown */
@media screen and(max-width:500px)  {
    

    .home-countdown{
        flex-direction: column;
        gap: 10px;
    }
    .home-countdown-count-heading{    
        font-size: 25px;
    }
    
    
    }
    /* ///////////////////// */
    
    /* Responsive adjustments */
    @media screen and (min-width:460) and (max-width:800px)  {
    .registernow-text-design{
        font-size: 20px;
        text-align: center;
    }

        
        }
      